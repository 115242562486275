<template>
  <div class="mb-20 mt-30 max-sm:my-20">
    <span v-if="!objSelected?.selling_plan" class="mb-15 block text-xl">
      {{ objSelected?.price }}
      <span
        class="ml-5 inline-block text-base line-through"
        v-if="objSelected?.price < objSelected?.compare_at_price"
      >
        {{ objSelected?.compare_at_price }}</span
      >
    </span>
    <div class="flex gap-10 font-bionM" v-if="data?.length > 1">
      <button
        class="flex h-[45px] min-w-75 items-center justify-center border-[2px] border-ss3_gray px-5 text-ss3_gray max-sm:h-30"
        :class="{' bg-ss3_gray text-white': variant.id === selectedVariant}"
        v-for="(variant, index) in data"
        :key="variant.id"
        @click="onSetVariant(variant.id, index)"
      >
        {{ variant?.title }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "ProductVariants",

  props: {
    variants: {
      type: String,
      default: "",
    },
    current: {
      type: String,
      default: "",
    },
    default: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: JSON.parse(this.variants),
    };
  },
  computed: {
    ...mapState("product", ["selectedVariant"]),
    objSelected() {
      return this.data.find(item => item.id === this.selectedVariant);
    },
  },
  methods: {
    ...mapActions("product", ["setProduct", "setVariant"]),
    onSetVariant(id, index) {
      const priceSubSave = document.querySelector("[data-price-subsave]");
      const priceOneTime = document.querySelector("[data-price-onetime]");

      if (priceSubSave && priceOneTime) {
        const obj = this.data.find(item => item.id === id);
        priceSubSave.style.fontSize = "0px";
        priceOneTime.style.fontSize = "0px";
        priceSubSave.setAttribute("data-price", obj?.selling_plan);
        priceOneTime.setAttribute("data-price", obj?.price);

        if (obj.price < obj.compare_at_price) {
          priceOneTime.setAttribute(
            "data-compare-price",
            obj?.compare_at_price,
          );
        }
      }
      this.setVariant({id, index});
    },

    setDefaultVariant() {
      if (this.default) {
        const index = this.default - 1;
        const productId = this.data[index]?.id;
        productId && this.onSetVariant(productId, index);
      }
    },
  },
  mounted() {
    const index = this.data.findIndex(item => item.id === this.current);
    this.onSetVariant(this.current, index);
    const observer = new MutationObserver((mutationsList, observer) => {
      const priceSubSave = document.querySelector("[data-price-subsave]");
      const priceOneTime = document.querySelector("[data-price-onetime]");

      if (priceSubSave && priceOneTime) {
        if (this.default) {
          this.setDefaultVariant();
        } else {
          this.onSetVariant(this.current, index);
        }

        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    this.setDefaultVariant();
  },

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style>
.rc_widget__price--subsave::before,
.rc_widget__price--onetime::before {
  content: attr(data-price);
  font-size: 16px !important;
}

.rc_widget__price--onetime::after {
  content: attr(data-compare-price);
  font-size: 16px !important;
  text-decoration-line: line-through;
  margin-left: 5px;
  @apply max-sm:ml-0 max-sm:!text-xs;
}
</style>
