<template>
  <div ref="faqs">
    <ul class="">
      <li
        v-for="(item, index) in this.data"
        :key="index"
        class="border-t border-b-ss3_gray border-t-ss3_gray last:border-b"
      >
        <div
          @click="activeIndex = index"
          class="flex cursor-pointer justify-between gap-5 py-[23px] max-md:items-start max-md:py-15 md:items-center"
        >
          <div class="flex gap-5 font-bionM max-md:leading-5 md:text-[20px]">
            <img
              v-if="item.icon"
              :src="item.icon"
              alt="icon"
              class="mt-[4px] h-20 w-20 flex-[0_0_20px] object-cover"
            />
            {{ item.question }}
          </div>

          <button>
            <svg
              class="h-[16px] w-[27px] max-md:mt-[2px] max-md:h-[17px] max-md:w-15"
              :class="{'rotate-180': activeIndex === index}"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 27 16"
              fill="none"
            >
              <g clip-path="url(#clip0_240_447)">
                <path
                  d="M25.4783 2.48071L13.5 13.5192L1.52168 2.48071"
                  stroke="#3F3F3F"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_240_447">
                  <rect
                    width="16"
                    height="27"
                    fill="white"
                    transform="translate(27) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div
          v-show="activeIndex === index"
          v-html="item.answer"
          class="spoller-content pb-35 pr-35 leading-[28px] max-md:left-20 max-md:pb-20 max-md:pr-20 max-md:leading-[20px] md:text-[20px]"
        ></div>
      </li>
    </ul>
  </div>
</template>

<script>
import {data} from "flickity";
import {ref} from "vue";

export default {
  name: "faqs",
  props: {
    questions: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  setup() {
    const activeIndex = ref(0);
    return {activeIndex};
  },
  mounted() {
    this.data = JSON.parse(this.questions.replace(/[\r\n]+/g, ""));
  },
};
</script>
<style>
.spoller-content p:not(:last-child) {
  margin-bottom: 15px;
}
.spoller-content a {
  text-align: center;
  text-decoration: underline;
  display: block;
}
</style>
