<template>
  <div class="border-gray flex border-b px-5 py-15 last:border-0">
    <div class="mr-5 w-100">
      <img
        class="rounded-lg"
        :src="item.image"
        :alt="item.featured_image.alt"
      />
    </div>

    <div class="w-full">
      <div class="flex justify-between gap-5">
        <!-- title -->
        <a :href="item.url">
          <h4
            class="tracking-px hover:text-blue my-5 text-sm font-medium leading-4"
          >
            {{ decodeHTML(item.title) }}
          </h4>
        </a>

        <span
          class="text-gray tracking-2p text-2xs cursor-pointer uppercase underline"
          @click="remove"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8431 6.15698L6.52943 17.4707"
              stroke="#2b3482"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
            <path
              d="M17.8431 17.4707L6.52943 6.15699"
              stroke="#2b3482"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
          </svg>
        </span>
      </div>

      <div class="flex items-center">
        <div class="max-md:mr-auto">
          <div
            class="border-darkBlue text-navy flex w-[75px] flex-row items-center justify-center border text-center text-sm font-normal leading-5"
          >
            <button @click="update('decrement')" class="p-5">
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.802612 1.17578H9.80261" stroke="#2b3482" />
              </svg>
            </button>
            <input
              type="text"
              style="background: transparent; outline: none; width: 35px"
              class="mx-auto border-none p-[2px] text-center"
              :value="item.quantity"
            />
            <button @click="update('increment')" class="p-5">
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.802612 6.17578H9.50391" stroke="#2b3482" />
                <path d="M5.15332 0.406494L5.15332 11.945" stroke="#2b3482" />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="item.selling_plan_allocation != undefined"
          class="text-blue mx-auto px-5 text-sm font-medium leading-none"
        >
          {{ item.selling_plan_allocation.selling_plan.name }}
        </div>
        <div class="ml-auto flex flex-col items-center">
          <div>
            <span
              class="tracking-px text-blue text-sm font-medium"
              v-text="formatMoney(item.final_price)"
            />
          </div>
          <div>
            <span
              v-if="item.price > item.final_price"
              class="tracking-px text-blue text-sm line-through"
              v-text="formatMoney(item.price)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import {formatMoney} from "@shopify/theme-currency";
export default {
  name: "CartItem",
  props: {
    item: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapState("cart", {
      cart: "cartData",
      loading: "loading",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions("cart", ["removeItem", "updateItem"]),
    remove() {
      this.removeItem(this.item);
    },
    update(type) {
      this.updateItem({
        item: this.item,
        type: type,
      });
    },
    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },
    hasVariantOrSubscription(item) {
      return item.variant_title || (item.properties && item.properties.details);
    },
    formatDiscountText({discount_application}) {
      if (discount_application.value_type === "percentage") {
        return `${Number(discount_application.value)}%`;
      } else {
        return `$${Number(discount_application.value)}`;
      }
    },
    decodeHTML(html) {
      let textarea = document.createElement("textarea");
      textarea.innerHTML = html;
      return textarea.value;
    },
  },
};
</script>
