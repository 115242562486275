<template>
  <div
    v-if="cartCount"
    class="absolute -right-[5px] bottom-[-5px] flex h-[17] w-[17px] items-center justify-center rounded-full border border-white bg-ss3_green text-xs text-white"
  >
    {{ cartCount }}
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
export default {
  name: "CartCount",
  props: {},
  data: () => {
    return {};
  },
  computed: {
    ...mapState("cart", {
      cart: "cartData",
      loading: "loading",
    }),
    cartCount() {
      return this.cart ? this.cart.item_count : null;
    },
  },
  methods: {
    ...mapActions("cart", ["show"]),
    openCart() {
      // console.log("clicked!");
      this.show();
    },
  },
};
</script>
