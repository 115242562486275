<template>
  <div class="relative">
    <button
      @click="onOpen"
      class="group flex items-center justify-center rounded-full"
    >
      <svg
        fill="none"
        stroke="#3F3F3F"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.96 14.92C11.8039 14.92 14.92 11.8039 14.92 7.96C14.92 4.1161 11.8039 1 7.96 1C4.1161 1 1 4.1161 1 7.96C1 11.8039 4.1161 14.92 7.96 14.92Z"
          stroke="#3F3F3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M18.69 19.82L12.48 13.61"
          stroke="#3F3F3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </button>
    <div
      v-if="open"
      class="shadow-header absolute right-0 top-[40px] z-[150] w-[390px] bg-pastelPink max-sm:right-[-78px] max-sm:w-[100vw]"
    >
      <button @click="onOpen" class="text-blackDark absolute left-10 top-[8px]">
        <svg
          class="block h-30 w-30"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.5 7.39355L7.39356 21.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
          <path
            d="M21.5 21.5L7.39356 7.39356"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
          ></path>
        </svg>
      </button>
      <input
        ref="inputSearch"
        type="text"
        placeholder="Search"
        v-model="search"
        @input="onInput"
        class="border-blue placeholder:text-blackDark h-45 w-full rounded-r-none border bg-[#F6EDF0] px-15 pl-40 outline-none placeholder:text-ss3_gray"
      />
      <div
        class="search-results border-blue max-h-[350px] overflow-y-auto border border-t-0 bg-[#F6EDF0] px-10 pt-10"
      >
        <div v-for="(item, index) in results?.slice(0, 5)" :key="index">
          <div class="flex items-center gap-10 py-10">
            <a :href="item.url" class="flex-[0_0_70px]">
              <img :src="item.image" class="h-70 w-70 object-cover" />
            </a>

            <a :href="item.url">
              <span class="mb-5 block text-xs">{{ item.sku }}</span>
              <span class="block text-[13px] font-medium hover:underline">{{
                item.title
              }}</span>
              <span class="text-blue block text-[13px] hover:underline"
                >${{ item.price }}</span
              >
            </a>
            <button
              class="group mb-auto ml-auto mt-auto flex h-[34px] w-[34px] flex-[0_0_34px] items-center justify-center rounded-full border hover:border-ss3_green hover:text-ss3_green"
              @click.stop="onAddToCart(item.variantId)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 22 21"
                fill="none"
              >
                <path
                  d="M5.49995 1C5.33328 3.16667 6.1 7 10.5 7C14.9 7 15.5207 2.5 15.5207 1"
                  stroke="#3F3F3F"
                  stroke-width="1.8"
                  class="group-hover:stroke-ss3_green"
                />
                <path
                  d="M2 1H19L20.1594 15.6852C20.343 18.0112 18.505 20 16.1718 20H5.21606C2.92433 20 1.10114 18.0783 1.22159 15.7898L2 1Z"
                  stroke="#3F3F3F"
                  stroke-width="1.8"
                  class="group-hover:stroke-ss3_green"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-if="results.length !== 0"
          class="sticky bottom-0 flex justify-center bg-pastelPink pb-10 pt-5"
        >
          <a
            :href="`/search?type=product&q=${search}`"
            class="btn-block mx-auto flex max-w-[210px]"
          >
            View all results
          </a>
        </div>

        <div class="flex flex-col justify-center" v-else>
          <p v-if="search.length !== 0" class="px-10 py-20 text-center">
            No products found for "{{ search }}"
          </p>
          <a
            href="/collections/shop-all"
            class="btn-block mx-auto mb-10 mt-5 max-w-[210px]"
          >
            View all products
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "Search",
  data() {
    return {
      search: "",
      results: [],
      open: false,
    };
  },

  methods: {
    ...mapActions("cart", {
      addItem: "addItem",
    }),
    onInput() {
      const term = this.search.trim();
      const searchURL = `/search/suggest.json?q=${this.search}&resources[type]=product&resources[options][fields]=title,product_type,variants.title,variants.sku`;

      if (term.length > 3) {
        fetch(searchURL)
          .then(response => response.json())
          .then(data => {
            const products = data.resources.results.products || [];
            return Promise.all(
              products.map(product => {
                // If product ID is present, return the product as is
                if (product.variants[0]) {
                  return product;
                } else {
                  const productHandle = product.handle;
                  const productDetailURL = `/products/${productHandle}.json`;

                  // Fetch product details to get the ID
                  return fetch(productDetailURL)
                    .then(response => response.json())
                    .then(fullProductData => {
                      return {
                        ...product,
                        variantId: fullProductData?.product?.variants[0]?.id,
                        sku: fullProductData?.product?.variants[0]?.sku,
                      };
                    })
                    .catch(error => {
                      console.error(
                        `Error fetching details for product handle ${productHandle}:`,
                        error,
                      );
                      return product; // Return the original product data if the full request fails
                    });
                }
              }),
            );
          })
          .then(resultsWithVariantIds => {
            this.results = resultsWithVariantIds;
          })
          .catch(error => {
            console.error(error);
            this.results = [];
          });
      } else {
        this.results = [];
      }
    },
    onOpen() {
      this.open = !this.open;
      this.onFocusInput();
    },
    onAddToCart(varid) {
      this.addItem({id: varid, quantity: 1});
      this.onFocusInput();
    },
    onFocusInput() {
      this.$nextTick(() => {
        if (this.open) {
          this.$refs.inputSearch.focus();
        }
      });
    },
  },
};
</script>
