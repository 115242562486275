<template>
  <slot @click="this.show"></slot>
  <div
    class="shadow-header text-blackDark fixed bottom-0 top-0 z-50 flex w-full flex-col bg-white md:w-1/4"
    :class="{
      '-right-full': !visible,
      'right-0': visible,
    }"
  >
    <StoreCart />
  </div>
</template>

<script>
import {mapState} from "vuex";
import StoreCart from "./StoreCart.vue";
export default {
  name: "Cart",
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("cart", ["cartData", "visible", "loading"]),
  },
  components: {
    StoreCart,
  },
};
</script>
