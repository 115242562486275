const state = {
  sortBy: "",
  filterBy: [],
  priceForm: 0,
  priceTo: Infinity,
};

const mutations = {
  setSort(state, value) {
    state.sortBy = value;
  },
  setFilter(state, value) {
    const index = state.filterBy.indexOf(value);
    if (index === -1) {
      state.filterBy.push(value);
    } else {
      state.filterBy.splice(index, 1);
    }
  },
  setPriceFrom(state, value) {
    state.priceFrom = value;
  },
  setPriceTo(state, value) {
    state.priceTo = value;
  },
};
const actions = {
  updatePriceFrom({commit}, value) {
    commit("setPriceFrom", value);
  },
  updatePriceTo({commit}, value) {
    commit("setPriceTo", value);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
