const state = {
  active: "Allergies",
  color: "#90C340",
};

const mutations = {
  setSelect(state, value) {
    state.active = value;
  },
  setColor(state, value) {
    state.color = value;
  },
};
const actions = {
  updateSelect({commit}, value) {
    commit("setSelect", value);
  },
  updateColor({commit}, value) {
    commit("setColor", value);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
