<template>
  <nav
    class="mb-50 flex flex-wrap items-baseline justify-center gap-70 max-md:gap-30 max-sm:mb-0"
  >
    <button
      v-for="(item, index) in this.dataArr"
      :key="index"
      class="image-control flex max-w-fit flex-col items-center justify-center font-bionM max-sm:flex-[0_0_33%] max-sm:gap-5 max-sm:text-sm max-sm:uppercase"
      :class="{
        'underline-position max-sm:underline': active === item.title,
        [`active${index + 1}`]: active === item.title,
      }"
      @click="updateSelect(item.title)"
      :style="`${active === item.title ? `color:${color}` : ''}`"
    >
      <img
        :src="item.icon"
        :alt="item.title"
        class="mb-10 h-45 w-50 object-contain object-bottom max-sm:h-30 max-sm:w-30"
      />
      <span class="block max-w-[100px] text-center">
        {{ item.title }}
      </span>
    </button>
  </nav>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "collection-nav",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataArr: JSON.parse(this.data),
    };
  },
  computed: {
    ...mapState("bestselers-section", ["active", "color"]),
    dotHelper() {
      return this.sl
        ? [...Array(this.sl?.track?.details?.slides?.length).keys()]
        : [];
    },
  },
  methods: {
    ...mapActions("bestselers-section", ["updateSelect"]),
  },
  mounted() {
    // console.log(this.dataArr);
  },
};
</script>
<style>
.image-control img {
  filter: brightness(0) saturate(100%) invert(23%) sepia(7%) saturate(19%)
    hue-rotate(4deg) brightness(96%) contrast(92%);
}

.image-control.active1 img {
  filter: brightness(0) saturate(100%) invert(79%) sepia(9%) saturate(3051%)
    hue-rotate(36deg) brightness(94%) contrast(79%);
}
.image-control.active2 img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(65%) saturate(1554%)
    hue-rotate(340deg) brightness(99%) contrast(91%);
}
.image-control.active3 img {
  filter: brightness(0) saturate(100%) invert(49%) sepia(46%) saturate(1496%)
    hue-rotate(306deg) brightness(97%) contrast(95%);
}
.image-control.active4 img {
  filter: brightness(0) saturate(100%) invert(80%) sepia(46%) saturate(4511%)
    hue-rotate(175deg) brightness(88%) contrast(90%);
}

.image-control.active5 img {
  filter: brightness(0) saturate(100%) invert(71%) sepia(65%) saturate(370%)
    hue-rotate(4deg) brightness(105%) contrast(95%);
}
.underline-position {
  text-underline-position: under;
}
</style>
