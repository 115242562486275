export default {
  methods: {
    scrollTo(el, height) {
      let isDesktop = matchMedia("(min-width: 1024px)").matches;
      let mobHeight = height ? height.mobile : 60;
      let deskHeight = height ? height.desktop : 80;

      let headerHeight = isDesktop ? deskHeight : mobHeight;

      let targetY = el.getBoundingClientRect().top;
      let currentY = window.scrollY;
      let scrollY = currentY + targetY - headerHeight;

      scroll({
        top: scrollY,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
