<template>
  <div
    class="mb-40 flex justify-between gap-10 max-sm:mb-30 max-sm:flex-col-reverse"
  >
    <div>
      <span class="block text-[12px] uppercase">FILTER</span>
      <div class="mb-20 flex gap-35">
        <div class="relative">
          <span
            class="select__dropdown relative block cursor-pointer bg-pastelPink py-5 font-bionM text-[14px] outline-none"
            @click="onOpenAvailable"
            >Availability</span
          >
          <div
            v-if="isOpenAvailable"
            class="absolute left-0 top-[100%] w-[200px] bg-pastelPink p-10 shadow-md"
          >
            <label
              class="flex cursor-pointer gap-5 py-5"
              v-for="item in available"
              :key="item.name"
              @click.prevent="updateAvailable(item.value)"
            >
              <input
                type="checkbox"
                :id="'checkbox_' + item.value"
                :checked="isChecked(item.value)"
              />
              {{ item.name }}
            </label>
          </div>
        </div>
        <div class="relative">
          <span
            class="select__dropdown relative block cursor-pointer bg-pastelPink py-5 font-bionM text-[14px] outline-none"
            @click="onOpenPrice"
            >Price</span
          >
          <div
            v-if="isOpenPrice"
            class="absolute left-0 top-[100%] w-[350px] bg-pastelPink p-10 shadow-md max-sm:left-[-150px]"
          >
            <div class="flex items-center gap-5">
              <span>$</span>
              <input
                placeholder="From"
                v-model.number="priceFrom"
                @input="updatePriceFrom(priceFrom)"
                type="number"
                class="mb-5 h-40 w-full border bg-white px-15 outline-none placeholder:text-[#9FAEA7]"
              />
              <span>$</span>
              <input
                placeholder="To"
                v-model.number="priceTo"
                @input="updatePriceTo(priceTo)"
                type="number"
                class="h-40 w-full border bg-white px-15 outline-none placeholder:text-[#9FAEA7]"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-5">
        <button
          v-for="(item, index) in filterBy"
          :key="index"
          class="flex items-center gap-10 rounded-full border border-pastelPinkL px-10 font-bionM text-sm text-pastelPinkL outline-none"
          @click="updateAvailable(item)"
        >
          Availability: {{ item }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
          >
            <line
              x1="0.353553"
              y1="0.646447"
              x2="10.3536"
              y2="10.6464"
              stroke="#8E297A"
            />
            <line
              y1="-0.5"
              x2="14.1421"
              y2="-0.5"
              transform="matrix(-0.707107 0.707107 0.707107 0.707107 11 1)"
              stroke="#8E297A"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="">
      <div class="flex gap-25 sm:items-end">
        <div class="">
          <span class="block text-[12px] uppercase sm:px-10">SORT BY</span>
          <select
            @change="updateSort"
            class="select__dropdown w-[120px] bg-pastelPink py-5 font-bionM text-[14px] outline-none sm:px-10"
          >
            <option selected>Feature</option>
            <option value="low_to_high">Low to High</option>
            <option value="high_to_low">High to Low</option>
          </select>
        </div>

        <div class="">
          <span class="mb-[6px] block whitespace-nowrap text-[14px]"
            >{{ count }} Products</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
export default {
  name: "selects-filter",
  data() {
    return {
      available: [
        {value: "in_stock", name: "In Stock"},
        {value: "out_of_stock", name: "Out Of Stock"},
      ],
      isOpenAvailable: false,
      isOpenPrice: false,
      count: 0,
    };
  },
  computed: {
    ...mapState("filter", ["filterBy", "sortBy", "priceFrom", "priceTo"]),
    isChecked() {
      return value => this.filterBy.includes(value);
    },
    priceFrom: {
      get() {
        return this.$store.state.filter.priceFrom;
      },
      set(value) {
        if (value === "") {
          this.updatePriceFrom(NaN);
        } else {
          this.updatePriceFrom(value);
        }
      },
    },
    priceTo: {
      get() {
        return this.$store.state.filter.priceTo;
      },
      set(value) {
        if (value === "") {
          this.updatePriceTo(NaN);
        } else {
          this.updatePriceTo(value);
        }
      },
    },
  },
  methods: {
    ...mapActions("filter", ["updatePriceTo", "updatePriceFrom"]),
    updateAvailable(e) {
      this.$store.commit("filter/setFilter", e);
      console.log(e);
    },
    updateSort(e) {
      this.$store.commit("filter/setSort", e.target.value);
    },
    onOpenAvailable() {
      this.isOpenAvailable = !this.isOpenAvailable;
    },
    onOpenPrice() {
      this.isOpenPrice = !this.isOpenPrice;
    },
    countProducts() {
      setTimeout(() => {
        const productSlides = document.querySelectorAll(".product-slide");
        this.count = productSlides.length;
      }, 1);
    },
  },

  watch: {
    filterBy: {
      handler: "countProducts",
      deep: true,
    },
    sortBy: "countProducts",
    priceFrom: "countProducts",
    priceTo: "countProducts",
  },
  mounted() {
    this.countProducts();
  },
};
</script>
