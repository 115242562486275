<template>
  <button
    class="flex items-center"
    :class="{
      'btn-block  gap-10 max-md:h-30': !opened,
      'w-full justify-between border-t border-t-[2px] border-x-ss3_gray pb-[8px] pt-[4px] font-bionM':
        opened,
    }"
    @click.prevent="!opened ? (show = !show) : null"
  >
    <span class="hidden xs:block">Quick Add</span>
    <svg
      v-if="!opened"
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="{'rotate-180': show}"
    >
      <path
        d="M11.7197 0.709726L6.20973 6.21973L0.709727 0.709725"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      ></path>
    </svg>
  </button>
  <div
    class="flex w-full flex-wrap gap-10"
    :class="{
      'absolute bottom-[100%] left-0   bg-pastelPink pb-15 pt-20 font-bionM group-[.form-praised]:bg-ss3_light_blue':
        !opened,
    }"
    v-if="show"
  >
    <button
      class="flex h-[26px] min-w-60 items-center justify-center rounded-[3px] border-[2px] border-ss3_gray px-10 font-bionM text-sm text-ss3_gray hover:border-ss3_purple hover:bg-ss3_purple hover:text-white focus:border-ss3_purple focus:bg-ss3_purple focus:text-white max-sm:h-30"
      v-for="variant in variants"
      :key="variant.id"
      @click.prevent="onEmit(variant.id)"
      :disabled="!variant?.available"
      :class="{'opacity-50': !variant?.available}"
    >
      {{ variant?.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: "VariantsDrop",

  props: {
    variants: {
      type: Array,
      default: [],
    },
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: this.opened ? true : false,
    };
  },
  emits: ["addToCart"],

  methods: {
    onEmit(id) {
      this.$emit("addToCart", id);
    },
  },

  mounted() {
    // console.log(this.opened);
  },
};
</script>
