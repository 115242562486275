<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "AddToCart",
  data: () => {
    return {
      loading: false,
      foo: "bar",
      selling_plan: null,
    };
  },
  computed: {
    ...mapState("product", ["quantity", "selectedVariant"]),

    // disabled() {
    //   return this.loading || !this.selectedVariant;
    // },
  },
  methods: {
    ...mapActions("cart", ["addItem"]),

    handleSubmit(idProduct) {
      // console.log("handle submit: ", idProduct);

      this.setPlan();
      const id =
        typeof idProduct === "object" && idProduct instanceof Event
          ? this.selectedVariant
          : idProduct;

      if (this.selling_plan) {
        this.addItem({
          id: id,
          quantity: this.quantity || 1,
          selling_plan: this.selling_plan,
        });
      } else {
        this.addItem({
          id: id,
          quantity: this.quantity || 1,
        });
      }
    },
    setPlan() {
      const itemFound = document.querySelector(
        ".rc_widget__option--subsave.rc_widget__option--active",
      );
      if (itemFound) {
        this.selling_plan = true;
        const inputElement = itemFound
          .querySelector("input")
          .getAttribute("name");
        this.selling_plan = inputElement.slice(13);
      } else {
        this.selling_plan = null;
      }
    },
  },
  mounted() {
    const observer = new MutationObserver(mutationsList => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const itemFound = document.querySelector(
            ".rc_widget__option--subsave.rc_widget__option--active",
          );
          if (itemFound) {
            this.setPlan();
          } else {
            this.selling_plan = null;
          }
        }
      }
    });
    observer.observe(document.body, {childList: true, subtree: true});

    function checkElementAvailability() {
      var selector = document.querySelector(".rc-selling-plans__dropdown");
      if (selector !== null) {
        selector.children[0].selected = true;

        selector.dispatchEvent(new Event("change"));
      } else {
        // If element is not found, recheck after 100ms
        setTimeout(checkElementAvailability, 1);
      }
    }

    document.addEventListener("DOMContentLoaded", checkElementAvailability);
  },

  render() {
    return this.$slots.default({
      loading: this.loading,
      disabled: this.disabled,
      addToCart: this.handleSubmit,
    });
  },
};
</script>
