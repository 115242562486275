<template>
  <div
    class="inline-flex h-[26px] max-w-fit items-center justify-center rounded-[3px] px-5 pt-[2px] text-sm uppercase max-sm:h-[24px] max-sm:text-xs"
    :class="{
      'mb-5 bg-ss3_gray text-white': !white,
      'mb-[12px] border border-black bg-white text-ss3_gray': white,
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Calming",
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
