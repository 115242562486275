<script>
import {ref} from "vue";

import {Carousel, Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "@fancyapps/ui/dist/carousel/carousel.css";

import {Thumbs} from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.thumbs.css";

export default {
  props: {
    options: Object,
    imgs: Object,
  },
  setup() {
    const fcInstance = ref(null);

    return {
      fcInstance,
    };
  },
  methods: {
    initFnacy() {
      Fancybox.bind(this.$refs.carousel, "[data-fancybox]", {
        ...(this.options || {}),
      });
      const fcInstance = new Carousel(
        this.$refs.carousel,
        {
          ...(this.options || {}),
        },
        {
          Thumbs,
        },
      );

      this.fcInstance = fcInstance;
    },
  },
  mounted() {
    setTimeout(() => {
      this.initFnacy();
    }, 0);
  },
  unmounted() {
    if (this.fcInstance) {
      this.fcInstance.destroy();
      this.fcInstance = null;
      Fancybox.destroy();
    }
  },
  updated() {
    Fancybox.unbind(this.$refs.carousel);
    Fancybox.close();

    Fancybox.bind(this.$refs.carousel, "[data-fancybox]", {
      ...(this.options || {}),
    });
  },
};
</script>

<template>
  <div class="f-carousel" ref="carousel">
    <slot></slot>
  </div>
</template>

<style></style>
