<template>
  <div v-if="isOpen" class="bg-ss3_green px-10 py-[12px] text-ss3_gray">
    <div
      class="container flex justify-center text-center font-bionM max-sm:text-sm"
    >
      <div v-if="content" v-html="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AnnouncementBar",
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: this.visible,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
};
</script>
