<script>
import {ref, watch} from "vue";

export default {
  setup(props, {slots}) {
    const initialRender = ref(true);
    const currentImage = ref(null);
    const isOpenPopup = ref(false);

    const switchImage = $el => {
      initialRender.value = false;
      currentImage.value = {
        url: $el.target.dataset.url,
        alt: $el.target.dataset.alt || "",
      };
    };
    const onOpenPopup = () => {
      isOpenPopup.value = !isOpenPopup.value;
    };
    // Watch for changes to isOpenPopup and add/remove class on html
    watch(isOpenPopup, newVal => {
      if (newVal) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "";
      }
    });
    return () =>
      slots.default({
        initialRender: initialRender.value,
        currentImage: currentImage.value,
        switchImage,
        onOpenPopup,
        isOpenPopup: isOpenPopup.value,
      });
  },
};
</script>
