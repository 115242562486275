<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "AddToCartMisc",
  data: () => {
    return {
      loading: false,
      foo: "bar",
    };
  },
  computed: {
    ...mapState("product", ["quantity", "selectedVariant"]),

    disabled() {
      return this.loading || !this.selectedVariant;
    },
  },
  methods: {
    ...mapActions("cart", ["addItem"]),

    handleSubmit(e) {
      console.log("e", e, typeof e);
      if (e) {
        console.log("inside if", e);
        this.addItem({
          id: e,
          quantity: 1,
        });
      }
    },
  },

  render() {
    return this.$slots.default({
      loading: this.loading,
      disabled: this.disabled,
      addToCart: this.handleSubmit,
    });
  },
};
</script>
